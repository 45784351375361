import {
  ParsedSnsType,
  SnsErrorType,
} from '../../pages/mykemi/social/source/Social';

import {
  ACCEPTABLE_IMAGE_TYPE,
  COMMON_MESSAGE,
  MAX_IMAGE_SIZE_BYTE,
  SNS_PREFIX,
} from '@global/constants';
import { REGEX_LOWERCASE_NUMBER_UNDERBAR } from '@global/regex';
import { ImageAlertType } from '@global/types';
import i18n from '@i18n/index';
import {
  EProductKemiSalesPeriodState,
  ESellerSnsType,
  ESnsType,
} from '@schema/types';
import { isValidEmail, trimAllSpace } from '@utils/string';

// TODO 케미링크 검증 서버로 위임
const UNAVAILABLE_ROUTE = [
  '[linkName]',
  '404',
  '500',
  'mykemi',
  'api',
  'app',
  'callback',
  'debug-mode',
  'google-callback',
  'info',
  'kakao-callback',
  'landing',
  'naver-callback',
  'orders',
  'pccc',
  'cart',
];

export const LINKNAME_ERROR_MESSAGE = {
  CHARACTER_COMBINATION: i18n.t('k_at_least_2_characters'),
  UNDER_MINIMUM_LETTERS: i18n.t('k_at_least_2_characters'),
  LINKNAME_MAX_LENGTH: i18n.t('k_cant_be_longer_than_20_characters'),
  LINKNAME_ALREADY_EXIST: i18n.t(
    'k_you_cannot_use_the_same_address_as_another_user'
  ),
  FORBIDDEN_ROUTE: (word: string) =>
    i18n.t('k_contains_invalid_words', { invalid_word: word }),
};

export const linkNameValidator = (linkName: string): string[] | undefined => {
  if (UNAVAILABLE_ROUTE.includes(linkName)) {
    return [LINKNAME_ERROR_MESSAGE.FORBIDDEN_ROUTE(linkName)];
  }

  if (!REGEX_LOWERCASE_NUMBER_UNDERBAR.test(linkName)) {
    return [LINKNAME_ERROR_MESSAGE.CHARACTER_COMBINATION];
  }

  if (!linkName || linkName?.length === 0) {
    return [LINKNAME_ERROR_MESSAGE.UNDER_MINIMUM_LETTERS];
  }

  if (linkName.trim().length < 2) {
    return [LINKNAME_ERROR_MESSAGE.UNDER_MINIMUM_LETTERS];
  }

  return COMMON_MESSAGE.NO_ERROR;
};

export const snsPrefixSetter = (
  input: string,
  type: ESnsType | ESellerSnsType
) => {
  if (SNS_PREFIX[type].includes(input)) {
    return input;
  }

  if (input.slice(0, SNS_PREFIX[type].length) !== SNS_PREFIX[type]) {
    return SNS_PREFIX[type] + input;
  }

  return input;
};

export const snsValidator = (sns: ParsedSnsType): SnsErrorType => {
  return {
    EMAIL: emailValidator(trimAllSpace(sns.EMAIL)),
    INSTAGRAM: instagramValidator(trimAllSpace(sns.INSTAGRAM)),
    YOUTUBE: youtubeValidator(trimAllSpace(sns.YOUTUBE)),
    NAVERBLOG: naverBlogValidator(trimAllSpace(sns.NAVERBLOG)),
    FACEBOOK: facebookValidator(trimAllSpace(sns.FACEBOOK)),
    TWITTER: twitterValidator(trimAllSpace(sns.TWITTER)),
    TIKTOK: tiktokValidator(trimAllSpace(sns.TIKTOK)),
  };
};

export const emailValidator = (email: string) => {
  if (email.length === 0) {
    return;
  }

  if (isValidEmail(email.toLowerCase())) {
    return;
  }

  return [i18n.t('k_invalid_format_please_check_and_try_again')];
};

const instagramValidator = (instagram: string) => {
  if (instagram.length === 0) {
    return;
  }

  if (instagram.includes('@') && instagram.length > 1) {
    return;
  }

  return [i18n.t('k_invalid_format_please_check_and_try_again')];
};

const youtubeValidator = (youtube: string) => {
  if (youtube.length === 0) {
    return;
  }

  if (youtube.includes('youtube.com/')) {
    return;
  }

  return [i18n.t('k_invalid_format_please_check_and_try_again')];
};

const naverBlogValidator = (naverBlog: string) => {
  if (naverBlog.length === 0) {
    return;
  }

  if (naverBlog.includes('blog.naver.com/')) {
    return;
  }

  return [i18n.t('k_invalid_format_please_check_and_try_again')];
};

const facebookValidator = (facebook: string) => {
  if (facebook.length === 0) {
    return;
  }

  if (facebook.includes('facebook.com/')) {
    return;
  }

  return [i18n.t('k_invalid_format_please_check_and_try_again')];
};

const twitterValidator = (twitter: string) => {
  if (twitter.length === 0) {
    return;
  }

  if (twitter.includes('@') && twitter.length > 1) {
    return;
  }

  return [i18n.t('k_invalid_format_please_check_and_try_again')];
};

const tiktokValidator = (tiktok: string) => {
  if (tiktok.length === 0) {
    return;
  }

  if (tiktok.includes('@') && tiktok.length > 1) {
    return;
  }

  return [i18n.t('k_invalid_format_please_check_and_try_again')];
};

function checkImageType(type: string) {
  const [category, detail] = type.split('/');

  if (category !== 'image') return false;

  if (!ACCEPTABLE_IMAGE_TYPE.includes(detail)) return false;

  return true;
}

export const imageValidator = (file: File) => {
  if (file.size > MAX_IMAGE_SIZE_BYTE) {
    return [ImageAlertType.OVERSIZE];
  }

  if (!checkImageType(file.type)) {
    return [ImageAlertType.WRONG_TYPE];
  }

  return;
};

export const isBase64 = (string: string) => {
  return string.startsWith('data:image');
};

export const showProductCard = (period: EProductKemiSalesPeriodState) => {
  return period !== 'AFTER';
};
