import { useRouter } from 'next/router';

import usp01 from '../assets/03-1-seller-usp-01.png';
import usp02 from '../assets/03-2-seller-usp-02.png';
import usp03 from '../assets/03-3-seller-usp-03.png';
import usp04 from '../assets/03-4-seller-usp-04.png';
import usp05 from '../assets/03-5-seller-usp-05.png';
import usp06 from '../assets/03-6-seller-usp-06.png';

import IntroductionCard from './IntroductionCard';
import LandingButton from './LandingButton';

import { EVENT_TAG } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import { styled } from '@styles/stitches.config';

const SellerServiceIntroduction = () => {
  const router = useRouter();

  const signInAndUpModal = useSignInAndUpModal();

  const handleClickSignUp = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.LANDING.LD_SELLER_FREESTART_CLICK
    );

    signInAndUpModal.open({
      purpose: 'normal',
      type: 'signUp',
      redirectTo: router.asPath,
    });
  };

  const introductionData = [
    {
      title: i18n.t('k_landing_seller_service_introduction_1_title'),
      imageSrc: usp01,
      description: i18n.t(
        'k_landing_seller_service_introduction_1_description'
      ),
      wide: true,
    },
    {
      title: i18n.t('k_landing_seller_service_introduction_2_title'),
      imageSrc: usp02,
      description: i18n.t(
        'k_landing_seller_service_introduction_2_description'
      ),
      wide: true,
    },
    {
      title: i18n.t('k_landing_seller_service_introduction_3_title'),
      imageSrc: usp03,
      description: i18n.t(
        'k_landing_seller_service_introduction_3_description'
      ),
      wide: true,
    },
    {
      title: i18n.t('k_landing_seller_service_introduction_4_title'),
      imageSrc: usp04,
      description: i18n.t(
        'k_landing_seller_service_introduction_4_description'
      ),
      wide: true,
    },
    {
      title: i18n.t('k_landing_seller_service_introduction_5_title'),
      imageSrc: usp05,
      description: i18n.t(
        'k_landing_seller_service_introduction_5_description'
      ),
      wide: true,
    },
    {
      title: i18n.t('k_landing_seller_service_introduction_6_title'),
      imageSrc: usp06,
      description: i18n.t(
        'k_landing_seller_service_introduction_6_description'
      ),
      wide: true,
    },
  ];

  return (
    <>
      <IntroductionCards>
        {introductionData.map((data, i) => (
          <IntroductionCard key={i} {...data} />
        ))}
      </IntroductionCards>
      <Container>
        <LandingButton
          text={i18n.t('k_get_started_now_for_free')}
          theme={'black'}
          onClick={handleClickSignUp}
        />
      </Container>
    </>
  );
};

const IntroductionCards = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  width: '100%',
  marginY: 120,

  '@landingPC': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const Container = styled('div', {
  margin: '0 auto',
  width: '100%',
});

export default SellerServiceIntroduction;
