import mainImage from '../assets/01-seller-top.gif';
import Header from '../components/Header';
import KemiData from '../components/KemiData';
import KemiSellerPreviews from '../components/KemiSellerPreviews';
import LandingFooter from '../components/LandingFooter';
import MainImageAndLinkNameInput from '../components/MainImageAndLinkNameInput';
import ReadyToStart from '../components/ReadyToStart';
import Section from '../components/Section';
import SellerFeatures from '../components/SellerFeatures';
import SellerServiceIntroduction from '../components/SellerServiceIntroduction';
import SellerStatistics from '../components/SellerStatistics';

import { EVENT_TAG } from '@global/constants';
import { useLogFirebase } from '@global/service/logger/EventHandler';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';
import DoYouHaveTroubleBubbles from 'src/landing/components/DoYouHaveTroubleBubbles';
import FAQ from 'src/landing/components/FAQ';

const Landing = () => {
  useLogFirebase(
    UserInteractionType.PAGE_IN,
    EVENT_TAG.LANDING.LD_HOME_SCREEN_OPEN
  );

  return (
    <Wrapper>
      <Content>
        <Header />
        <Section>
          <MainImageAndLinkNameInput
            imageSrc={mainImage}
            title={i18n.t('k_landing_seller_main_title')}
            description={i18n.t('k_landing_seller_main_description')}
            bgColor={'yellow100'}
          />
        </Section>
        <Section>
          <DoYouHaveTroubleBubbles />
        </Section>
        <Section>
          <SellerServiceIntroduction />
        </Section>
        <Section>
          <KemiSellerPreviews />
        </Section>
        <Section>
          <SellerStatistics />
        </Section>
        <Section>
          <SellerFeatures />
        </Section>
        <Section>
          <KemiData />
        </Section>
        <Section>
          <ReadyToStart />
        </Section>
        <Section>
          <FAQ />
        </Section>
      </Content>
      <LandingFooter />
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  fontFamily: 'Pretendard',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '$white',
});

const Content = styled('div', {
  width: '400px',
  maxWidth: '100%',
  backgroundColor: '$white',

  '@landingTablet': {
    width: '800px',
  },
  '@landingPC': {
    width: '1200px',
  },
});

export default Landing;
