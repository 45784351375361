import i18n from '@i18n/index';
import ROUTE from '@routers';
import Text from '@shared/components/Text';
import { styled } from '@styles/stitches.config';

type Props = {
  transparent?: boolean;
};

function Footer({ transparent }: Props) {
  return (
    <Box transparent={transparent}>
      <Text type={'subtitleBold'} color={'$grey100'}>
        {i18n.t('k_wiredcompany')}
      </Text>
      <Contents>
        <List>
          <Label>{i18n.t('k_ceo')}</Label>
          <Value>{i18n.t('k_ceo_value')}</Value>
        </List>
        <List>
          <Label>{i18n.t('k_corporate_register_number')}</Label>
          <Value>{'631-87-01013'}</Value>
          <a href={ROUTE.businessInfo} target={'_blank'} rel={'noreferrer'}>
            <BusinessInfo>{'[사업자정보 확인]'}</BusinessInfo>
          </a>
        </List>
        <List>
          <Label>{i18n.t('k_online_business')}</Label>
          <Value>{'2018-서울강남-02902'}</Value>
        </List>
        <List>
          <Label>{i18n.t('k_business_address')}</Label>
          <Value>{i18n.t('k_business_address_value')}</Value>
        </List>
        <List>
          <Label>{i18n.t('k_business_tel_number')}</Label>
          <Value>{'1533-2909'}</Value>
        </List>
      </Contents>
      <Disclaimer>{i18n.t('k_footer_disclaimer')}</Disclaimer>
      <Terms>
        <a href={ROUTE.terms} target={'_blank'} rel={'noreferrer'}>
          <Value>{i18n.t('k_terms_of_use')}</Value>
        </a>
        <Divider />
        <a href={ROUTE.privacy} target={'_blank'} rel={'noreferrer'}>
          <Value>{i18n.t('k_privacy_policy')}</Value>
        </a>
      </Terms>
    </Box>
  );
}

const Box = styled('div', {
  background: '$white',
  variants: {
    transparent: {
      true: {
        background: 'none',
      },
    },
  },
});

const Contents = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  marginTop: 20,
});

const List = styled('div', {
  display: 'flex',
});

const Label = styled('div', {
  width: 96,
  fontType: 'captionRegular',
  color: '$grey100',
});

const Value = styled('div', {
  fontType: 'captionRegular',
  color: '$grey100',
});

const Disclaimer = styled('div', {
  fontType: 'captionRegular',
  color: '$grey100',
  marginTop: 16,
});

const Terms = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginTop: 16,
});

const Divider = styled('div', {
  width: 1,
  height: 12,
  marginX: 8,
  backgroundColor: '$grey50',
});

const BusinessInfo = styled('div', {
  fontType: 'captionBold',
  color: '$grey100',
  marginLeft: 8,
});

export default Footer;
