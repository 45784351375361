import sellerImage from '../assets/05-1-statistics-seller.gif';
import profitImage from '../assets/05-2-statistics-profit.gif';
import userImage from '../assets/05-3-statistics-user.gif';

import TitleAndDescription from './TitleAndDescription';

import i18n from '@i18n/index';
import CardList from 'src/landing/components/CardList';

const KemiData = () => {
  const kemiDataCard = [
    {
      imageSrc: sellerImage,
      description: i18n.t('k_companion_sellers'),
      title: `40,000${i18n.t('k_person_count')}+`,
    },
    {
      imageSrc: profitImage,
      description: i18n.t('k_average_earnings_per_market'),
      title: `6,200,000,000${i18n.t('k_price_suffix')}+`,
    },
    {
      imageSrc: userImage,
      description: i18n.t('k_cumulative_number_of_visitors'),
      title: `3,000,000${i18n.t('k_person_count')}+`,
    },
  ];

  return (
    <>
      <TitleAndDescription
        title={i18n.t('k_landing_kemi_data')}
        description={null}
      />
      <CardList
        gridType={'twoRowsInFirstColumn'}
        bgColor={'purple'}
        contentOrder={'imageDescriptionTitle'}
        contentGap={8}
        cardAlign={'center'}
        contentPadding={64}
        cards={kemiDataCard}
      />
    </>
  );
};
export default KemiData;
