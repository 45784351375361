import image1 from '../assets/05-1-seller-usp-01.png';
import image2 from '../assets/05-2-seller-usp-02.png';

import TitleAndDescription from './TitleAndDescription';

import Image from '@global/components/atoms/Image';
import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';

const SellerFeatures = () => {
  const featureData = [
    {
      title: i18n.t('k_landing_seller_feature_card_1_title'),
      imageSrc: image1,
      description: i18n.t('k_landing_seller_feature_card_1_description'),
    },
    {
      title: i18n.t('k_landing_seller_feature_card_2_title'),
      imageSrc: image2,
      description: i18n.t('k_landing_seller_feature_card_2_description'),
    },
  ];

  return (
    <Container>
      <TitleAndDescription
        title={i18n.t('k_landing_seller_feature_title')}
        description={i18n.t('k_landing_seller_feature_description')}
      />
      <Cards>
        {featureData.map((feature, i) => (
          <Card key={i}>
            <ImageWrapper>
              <Image {...feature.imageSrc} alt={'seller-feature'} />
            </ImageWrapper>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </Card>
        ))}
      </Cards>
    </Container>
  );
};

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 136,
});

const Cards = styled('div', {
  display: 'grid',
  gap: 16,
  marginTop: 120,
  marginBottom: 120,
  width: '100%',

  '@landingTablet': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const Card = styled('div', {
  display: 'grid',
  gap: 24,
  borderRadius: 40,
  padding: 24,
  paddingBottom: 64,
  backgroundColor: '$yellow100',
  whiteSpace: 'pre-line',

  '& > h3': {
    fontType: 'heading3',
  },

  '& > p': {
    fontType: 'subtitleRegular',
  },
});

const ImageWrapper = styled('div', {
  margin: '0 auto',
  maxWidth: 328,
});

export default SellerFeatures;
