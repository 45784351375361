/* eslint-disable quotes */
import { useRouter } from 'next/router';

import LandingButton from './LandingButton';

import Input from '@global/components/atoms/Input';
import { EVENT_TAG, MAX_INPUT_LENGTH } from '@global/constants';
import { logFirebase } from '@global/service/logger/EventHandler';
import { linkNameValidator } from '@global/service/validator';
import { UserInteractionType } from '@global/types';
import i18n from '@i18n/index';
import useSignInAndUpModal from '@shared/hooks/useSignInAndUpModal';
import { styled } from '@styles/stitches.config';

type LinkNameInputProps = {
  linkName: string;
  onChange: (linkName: string) => void;
  validTextColor?: 'grey100' | 'grey20';
};

const LinkNameInput = ({
  linkName,
  onChange,
  validTextColor,
}: LinkNameInputProps) => {
  const router = useRouter();

  const signInAndUpModal = useSignInAndUpModal();

  const handleClickInput = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.LANDING.LD_HOME_KEMI_URI_INPUT_CLICK
    );
  };

  const handleClickStartKemi = () => {
    logFirebase(
      UserInteractionType.CLICK,
      EVENT_TAG.LANDING.LD_HOME_KEMISTART_CLICK,
      Object.assign({
        url_input: linkName,
      })
    );

    signInAndUpModal.open({
      purpose: 'normal',
      type: 'signUp',
      redirectTo: router.asPath,
      linkName,
    });
  };

  return (
    <>
      <LandingInput
        value={linkName}
        fixedPlaceholder={'kemi.io/'}
        fixedPlaceholderColor={'black'}
        placeholder={'name'}
        onChange={onChange}
        onClick={handleClickInput}
        maxLength={MAX_INPUT_LENGTH.LINKNAME}
        errorBorder={
          linkName.length >= 1 ? !!linkNameValidator(linkName) : false
        }
      />
      <ValidTextNotice color={validTextColor}>
        <span>{i18n.t('k_at_least_2_characters')}</span>
      </ValidTextNotice>
      <div>
        <LandingButton
          text={i18n.t('k_start_kemi')}
          theme={'black'}
          onClick={handleClickStartKemi}
        />
      </div>
    </>
  );
};

const LandingInput = styled(Input, {
  padding: '24px 32px !important',
  border: '1px solid #303030 !important',
  borderRadius: '32px !important',
  boxShadow: '8px 8px 0px #303030',
});

const ValidTextNotice = styled('div', {
  display: 'block',
  marginY: 16,
  width: '100%',
  textAlign: 'center',
  color: '$grey20',

  variants: {
    color: {
      grey20: {
        color: '$grey20',
      },
      grey100: {
        color: '$grey100',
      },
    },
  },

  '& > span': {
    fontType: 'labelRegular',
  },
});

export default LinkNameInput;
