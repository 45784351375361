import preview1 from '../assets/02-1-seller-kemi-preview-01.png';
import preview2 from '../assets/02-2-seller-kemi-preview-02.png';
import preview3 from '../assets/02-3-seller-kemi-preview-03.png';
import preview4 from '../assets/02-4-seller-kemi-preview-04.png';
import preview5 from '../assets/02-5-seller-kemi-preview-05.png';

import KemiPreviews from './KemiPreviews';

import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';

const imageSrcs = [
  preview1,
  preview2,
  preview3,
  preview4,
  preview5,
  preview1,
  preview2,
  preview3,
  preview4,
  preview5,
];

const KemiSellerPreviews = () => {
  return (
    <KemiPreviews
      title={<Title>{i18n.t('k_landing_seller_preview_title')}</Title>}
      imageSrcs={imageSrcs}
    />
  );
};

const Title = styled('h2', {
  textAlign: 'left',
  whiteSpace: 'pre-line',
  fontType: 'heading3',
  color: '$black',
});

export default KemiSellerPreviews;
