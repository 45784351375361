import image1 from '../assets/09-1-statistics-feature-01.png';
import image2 from '../assets/09-2-statistics-feature-02.png';
import image3 from '../assets/09-3-statistics-feature-03.png';

import TitleAndDescription from './TitleAndDescription';

import Image from '@global/components/atoms/Image';
import i18n from '@i18n/index';
import { styled } from '@styles/stitches.config';

const SellerStatistics = () => {
  return (
    <Container>
      <TitleAndDescription
        title={i18n.t('k_landing_seller_statistics_title')}
        description={
          <>
            <Span>{i18n.t('k_landing_seller_statistics_description_1')}</Span>
            <Span>{'\n\n'}</Span>
            <BoldSpan>
              {i18n.t('k_landing_seller_statistics_description_2')}
            </BoldSpan>
          </>
        }
      />
      <FeatureCards>
        <FeatureCard>
          <ImageBox>
            <ImageWidthBox>
              <Image {...image1} alt={'kemi-statistics-image'} />
            </ImageWidthBox>
          </ImageBox>
          <Description>
            {i18n.t('k_landing_seller_statistics_card_1_description')}
          </Description>
        </FeatureCard>
        <FeatureCard>
          <ImageBox>
            <ImageWidthBox>
              <Image {...image2} alt={'kemi-statistics-image'} />
            </ImageWidthBox>
          </ImageBox>
          <Description>
            {i18n.t('k_landing_seller_statistics_card_2_description')}
          </Description>
        </FeatureCard>
        <FeatureCard>
          <ImageBox>
            <ImageWidthBox>
              <Image {...image3} alt={'kemi-statistics-image'} />
            </ImageWidthBox>
          </ImageBox>
          <Description>
            {i18n.t('k_landing_seller_statistics_card_3_description')}
          </Description>
          <Remark>{`*${i18n.t('k_scheduled_to_be_introduced')}`}</Remark>
        </FeatureCard>
      </FeatureCards>
    </Container>
  );
};

const Container = styled('div', {
  marginTop: 240,
});

const FeatureCards = styled('div', {
  display: 'grid',
  gap: 16,
  justifyContent: 'center',
  marginTop: 120,

  '@landingTablet': {
    gridTemplateColumns: 'repeat(2, 1fr)',

    '& > div:nth-child(1)': {
      gridColumn: '1 / 2',
      gridRow: '1 / 3',
    },

    '& > div:nth-child(2)': {
      gridColumn: '2 / 3',
      gridRow: '1 / 2',
    },

    '& > div:nth-child(3)': {
      gridColumn: '2 / 3',
      gridRow: '2 / 3',
    },
  },

  '@landingPC': {
    gridTemplateColumns: 'repeat(3, 1fr)',

    '& > div:nth-child(1)': {
      gridColumn: '1 / 2',
      gridRow: '1 / 2',
    },

    '& > div:nth-child(2)': {
      gridColumn: '2 / 3',
      gridRow: '1 / 2',
    },

    '& > div:nth-child(3)': {
      gridColumn: '3 / 4',
      gridRow: '1 / 2',
    },
  },
});

const FeatureCard = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 8,
  padding: 24,
  paddingBottom: 64,
  backgroundColor: '$blue50',
  borderRadius: 40,

  '@landingPC': {
    justifyContent: 'flex-start',
  },
});

const ImageBox = styled('div', {
  marginBottom: 24,

  '@landingPC': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 394,
  },
});

const ImageWidthBox = styled('div', {
  '@landingPC': {
    maxWidth: 320,
  },
});

const Description = styled('p', {
  fontType: 'subtitleRegular',
  whiteSpace: 'pre-line',
});

const BoldSpan = styled('span', {
  display: 'block',
  fontType: 'subtitleBold',
});

const Span = styled('span', {
  whiteSpace: 'pre-line',
});

const Remark = styled('span', {
  marginTop: 8,
  fontType: 'captionRegular',
  color: '$grey100',
});

export default SellerStatistics;
